exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-the-artist-js": () => import("./../../../src/pages/about-the-artist.js" /* webpackChunkName: "component---src-pages-about-the-artist-js" */),
  "component---src-pages-aesthetics-js": () => import("./../../../src/pages/aesthetics.js" /* webpackChunkName: "component---src-pages-aesthetics-js" */),
  "component---src-pages-art-js": () => import("./../../../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-the-artist-js": () => import("./../../../src/pages/contact-the-artist.js" /* webpackChunkName: "component---src-pages-contact-the-artist-js" */),
  "component---src-pages-hornsea-gallery-js": () => import("./../../../src/pages/hornsea-gallery.js" /* webpackChunkName: "component---src-pages-hornsea-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-to-paint-js": () => import("./../../../src/pages/learn-to-paint.js" /* webpackChunkName: "component---src-pages-learn-to-paint-js" */),
  "component---src-pages-masonry-test-js": () => import("./../../../src/pages/masonry-test.js" /* webpackChunkName: "component---src-pages-masonry-test-js" */),
  "component---src-pages-mosaics-index-js": () => import("./../../../src/pages/mosaics/index.js" /* webpackChunkName: "component---src-pages-mosaics-index-js" */),
  "component---src-pages-mosaics-sun-mosaic-js": () => import("./../../../src/pages/mosaics/sun-mosaic.js" /* webpackChunkName: "component---src-pages-mosaics-sun-mosaic-js" */),
  "component---src-pages-oil-paintings-blue-hand-js": () => import("./../../../src/pages/oil-paintings/blue-hand.js" /* webpackChunkName: "component---src-pages-oil-paintings-blue-hand-js" */),
  "component---src-pages-oil-paintings-buy-crypto-js": () => import("./../../../src/pages/oil-paintings/buy-crypto.js" /* webpackChunkName: "component---src-pages-oil-paintings-buy-crypto-js" */),
  "component---src-pages-oil-paintings-cotswold-gatehouse-js": () => import("./../../../src/pages/oil-paintings/cotswold-gatehouse.js" /* webpackChunkName: "component---src-pages-oil-paintings-cotswold-gatehouse-js" */),
  "component---src-pages-oil-paintings-derbyshire-dry-stone-wall-js": () => import("./../../../src/pages/oil-paintings/derbyshire-dry-stone-wall.js" /* webpackChunkName: "component---src-pages-oil-paintings-derbyshire-dry-stone-wall-js" */),
  "component---src-pages-oil-paintings-derbyshire-farm-window-js": () => import("./../../../src/pages/oil-paintings/derbyshire-farm-window.js" /* webpackChunkName: "component---src-pages-oil-paintings-derbyshire-farm-window-js" */),
  "component---src-pages-oil-paintings-gaia-js": () => import("./../../../src/pages/oil-paintings/gaia.js" /* webpackChunkName: "component---src-pages-oil-paintings-gaia-js" */),
  "component---src-pages-oil-paintings-gainsborough-old-hall-js": () => import("./../../../src/pages/oil-paintings/gainsborough-old-hall.js" /* webpackChunkName: "component---src-pages-oil-paintings-gainsborough-old-hall-js" */),
  "component---src-pages-oil-paintings-gawsworth-hall-js": () => import("./../../../src/pages/oil-paintings/gawsworth-hall.js" /* webpackChunkName: "component---src-pages-oil-paintings-gawsworth-hall-js" */),
  "component---src-pages-oil-paintings-haddon-hall-js": () => import("./../../../src/pages/oil-paintings/haddon-hall.js" /* webpackChunkName: "component---src-pages-oil-paintings-haddon-hall-js" */),
  "component---src-pages-oil-paintings-hands-of-the-artist-js": () => import("./../../../src/pages/oil-paintings/hands-of-the-artist.js" /* webpackChunkName: "component---src-pages-oil-paintings-hands-of-the-artist-js" */),
  "component---src-pages-oil-paintings-hanseatic-league-js": () => import("./../../../src/pages/oil-paintings/hanseatic-league.js" /* webpackChunkName: "component---src-pages-oil-paintings-hanseatic-league-js" */),
  "component---src-pages-oil-paintings-index-js": () => import("./../../../src/pages/oil-paintings/index.js" /* webpackChunkName: "component---src-pages-oil-paintings-index-js" */),
  "component---src-pages-oil-paintings-joust-js": () => import("./../../../src/pages/oil-paintings/joust.js" /* webpackChunkName: "component---src-pages-oil-paintings-joust-js" */),
  "component---src-pages-oil-paintings-lincoln-oriel-window-js": () => import("./../../../src/pages/oil-paintings/lincoln-oriel-window.js" /* webpackChunkName: "component---src-pages-oil-paintings-lincoln-oriel-window-js" */),
  "component---src-pages-oil-paintings-lions-head-fountain-js": () => import("./../../../src/pages/oil-paintings/lions-head-fountain.js" /* webpackChunkName: "component---src-pages-oil-paintings-lions-head-fountain-js" */),
  "component---src-pages-oil-paintings-meditating-woman-js": () => import("./../../../src/pages/oil-paintings/meditating-woman.js" /* webpackChunkName: "component---src-pages-oil-paintings-meditating-woman-js" */),
  "component---src-pages-oil-paintings-mother-nature-js": () => import("./../../../src/pages/oil-paintings/mother-nature.js" /* webpackChunkName: "component---src-pages-oil-paintings-mother-nature-js" */),
  "component---src-pages-oil-paintings-neptune-js": () => import("./../../../src/pages/oil-paintings/neptune.js" /* webpackChunkName: "component---src-pages-oil-paintings-neptune-js" */),
  "component---src-pages-oil-paintings-oxborough-hall-tudor-window-js": () => import("./../../../src/pages/oil-paintings/oxborough-hall-tudor-window.js" /* webpackChunkName: "component---src-pages-oil-paintings-oxborough-hall-tudor-window-js" */),
  "component---src-pages-oil-paintings-oxborough-hall-window-js": () => import("./../../../src/pages/oil-paintings/oxborough-hall-window.js" /* webpackChunkName: "component---src-pages-oil-paintings-oxborough-hall-window-js" */),
  "component---src-pages-oil-paintings-round-window-newstead-abbey-js": () => import("./../../../src/pages/oil-paintings/round-window-newstead-abbey.js" /* webpackChunkName: "component---src-pages-oil-paintings-round-window-newstead-abbey-js" */),
  "component---src-pages-oil-paintings-sleeping-kings-js": () => import("./../../../src/pages/oil-paintings/sleeping-kings.js" /* webpackChunkName: "component---src-pages-oil-paintings-sleeping-kings-js" */),
  "component---src-pages-oil-paintings-spider-colour-wheel-js": () => import("./../../../src/pages/oil-paintings/spider-colour-wheel.js" /* webpackChunkName: "component---src-pages-oil-paintings-spider-colour-wheel-js" */),
  "component---src-pages-oil-paintings-still-life-fruits-js": () => import("./../../../src/pages/oil-paintings/still-life-fruits.js" /* webpackChunkName: "component---src-pages-oil-paintings-still-life-fruits-js" */),
  "component---src-pages-oil-paintings-tree-of-life-js": () => import("./../../../src/pages/oil-paintings/tree-of-life.js" /* webpackChunkName: "component---src-pages-oil-paintings-tree-of-life-js" */),
  "component---src-pages-oil-paintings-venetian-window-js": () => import("./../../../src/pages/oil-paintings/venetian-window.js" /* webpackChunkName: "component---src-pages-oil-paintings-venetian-window-js" */),
  "component---src-pages-oil-paintings-windows-and-roses-js": () => import("./../../../src/pages/oil-paintings/windows-and-roses.js" /* webpackChunkName: "component---src-pages-oil-paintings-windows-and-roses-js" */),
  "component---src-pages-painting-technique-js": () => import("./../../../src/pages/painting-technique.js" /* webpackChunkName: "component---src-pages-painting-technique-js" */),
  "component---src-pages-printmaking-albert-einstein-js": () => import("./../../../src/pages/printmaking/albert-einstein.js" /* webpackChunkName: "component---src-pages-printmaking-albert-einstein-js" */),
  "component---src-pages-printmaking-hand-of-the-artist-js": () => import("./../../../src/pages/printmaking/hand-of-the-artist.js" /* webpackChunkName: "component---src-pages-printmaking-hand-of-the-artist-js" */),
  "component---src-pages-printmaking-index-js": () => import("./../../../src/pages/printmaking/index.js" /* webpackChunkName: "component---src-pages-printmaking-index-js" */),
  "component---src-pages-printmaking-les-paul-guitar-js": () => import("./../../../src/pages/printmaking/les-paul-guitar.js" /* webpackChunkName: "component---src-pages-printmaking-les-paul-guitar-js" */),
  "component---src-pages-printmaking-pine-cone-js": () => import("./../../../src/pages/printmaking/pine-cone.js" /* webpackChunkName: "component---src-pages-printmaking-pine-cone-js" */),
  "component---src-pages-printmaking-praying-hands-js": () => import("./../../../src/pages/printmaking/praying-hands.js" /* webpackChunkName: "component---src-pages-printmaking-praying-hands-js" */),
  "component---src-pages-printmaking-tyrannosaurus-rex-js": () => import("./../../../src/pages/printmaking/tyrannosaurus-rex.js" /* webpackChunkName: "component---src-pages-printmaking-tyrannosaurus-rex-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-test-masonry-js": () => import("./../../../src/pages/test-masonry.js" /* webpackChunkName: "component---src-pages-test-masonry-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-video-diaries-js": () => import("./../../../src/pages/video-diaries.js" /* webpackChunkName: "component---src-pages-video-diaries-js" */),
  "component---src-pages-videos-video-diary-2021-js": () => import("./../../../src/pages/videos/video-diary-2021.js" /* webpackChunkName: "component---src-pages-videos-video-diary-2021-js" */),
  "component---src-pages-videos-video-diary-2022-js": () => import("./../../../src/pages/videos/video-diary-2022.js" /* webpackChunkName: "component---src-pages-videos-video-diary-2022-js" */),
  "component---src-pages-videos-video-diary-2023-js": () => import("./../../../src/pages/videos/video-diary-2023.js" /* webpackChunkName: "component---src-pages-videos-video-diary-2023-js" */),
  "component---src-pages-videos-video-diary-2024-js": () => import("./../../../src/pages/videos/video-diary-2024.js" /* webpackChunkName: "component---src-pages-videos-video-diary-2024-js" */),
  "component---src-pages-watercolours-cotswold-gatehouse-js": () => import("./../../../src/pages/watercolours/cotswold-gatehouse.js" /* webpackChunkName: "component---src-pages-watercolours-cotswold-gatehouse-js" */),
  "component---src-pages-watercolours-derbyshire-barn-js": () => import("./../../../src/pages/watercolours/derbyshire-barn.js" /* webpackChunkName: "component---src-pages-watercolours-derbyshire-barn-js" */),
  "component---src-pages-watercolours-index-js": () => import("./../../../src/pages/watercolours/index.js" /* webpackChunkName: "component---src-pages-watercolours-index-js" */),
  "component---src-pages-watercolours-little-moreton-hall-js": () => import("./../../../src/pages/watercolours/little-moreton-hall.js" /* webpackChunkName: "component---src-pages-watercolours-little-moreton-hall-js" */),
  "component---src-pages-watercolours-medieval-brick-window-js": () => import("./../../../src/pages/watercolours/medieval-brick-window.js" /* webpackChunkName: "component---src-pages-watercolours-medieval-brick-window-js" */),
  "component---src-pages-watercolours-medieval-door-js": () => import("./../../../src/pages/watercolours/medieval-door.js" /* webpackChunkName: "component---src-pages-watercolours-medieval-door-js" */),
  "component---src-pages-watercolours-timber-framed-study-js": () => import("./../../../src/pages/watercolours/timber-framed-study.js" /* webpackChunkName: "component---src-pages-watercolours-timber-framed-study-js" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-an-attempt-at-a-definition-of-art-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/aesthetics/an-attempt-at-a-definition-of-art.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-an-attempt-at-a-definition-of-art-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-andy-warhol-mass-production-art-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/aesthetics/andy-warhol-mass-production-art.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-andy-warhol-mass-production-art-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-ayn-rand-aesthetics-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/aesthetics/ayn-rand-aesthetics.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-ayn-rand-aesthetics-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-chatgpt-and-philosophy-of-aesthetics-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/aesthetics/chatgpt-and-philosophy-of-aesthetics.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-chatgpt-and-philosophy-of-aesthetics-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-institutional-theory-of-art-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/aesthetics/institutional-theory-of-art.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-institutional-theory-of-art-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-philosophy-of-aesthetics-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/aesthetics/philosophy-of-aesthetics.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-philosophy-of-aesthetics-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-semiology-of-art-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/aesthetics/semiology-of-art.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-semiology-of-art-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-what-is-opposite-of-art-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/aesthetics/what-is-opposite-of-art.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-what-is-opposite-of-art-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-what-is-spirit-heart-soul-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/aesthetics/what-is-spirit-heart-soul.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-what-is-spirit-heart-soul-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-what-nietzsche-really-said-about-art-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/aesthetics/what-nietzsche-really-said-about-art.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-aesthetics-what-nietzsche-really-said-about-art-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-architecture-beautiful-carvings-at-beverley-minster-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/architecture/beautiful-carvings-at-beverley-minster.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-architecture-beautiful-carvings-at-beverley-minster-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-architecture-beautiful-painted-medieval-church-east-yorkshire-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/architecture/beautiful-painted-medieval-church-east-yorkshire.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-architecture-beautiful-painted-medieval-church-east-yorkshire-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-architecture-colourful-building-in-london-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/architecture/colourful-building-in-london.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-architecture-colourful-building-in-london-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-25-greatest-artists-of-all-time-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/25-greatest-artists-of-all-time.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-25-greatest-artists-of-all-time-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-aesthetics-of-cars-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/aesthetics-of-cars.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-aesthetics-of-cars-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-an-artists-social-isolation-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/an-artists-social-isolation.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-an-artists-social-isolation-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-art-and-meditation-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/art-and-meditation.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-art-and-meditation-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-art-created-by-dall-e-ai-engine-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/art-created-by-dall-e-ai-engine.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-art-created-by-dall-e-ai-engine-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-beryl-cook-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/beryl-cook.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-beryl-cook-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-english-church-as-art-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/english-church-as-art.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-english-church-as-art-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-eternal-beauty-of-roman-mosaics-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/eternal-beauty-of-roman-mosaics.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-eternal-beauty-of-roman-mosaics-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-favourite-art-and-artists-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/favourite-art-and-artists.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-favourite-art-and-artists-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-folk-art-derbyshire-well-dressings-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/folk-art/derbyshire-well-dressings.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-folk-art-derbyshire-well-dressings-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-folk-art-pebble-art-queen-elizabeth-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/folk-art/pebble-art-queen-elizabeth.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-folk-art-pebble-art-queen-elizabeth-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-folk-art-scarborough-street-art-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/folk-art/scarborough-street-art.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-folk-art-scarborough-street-art-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-food-as-art-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/food-as-art.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-food-as-art-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-islamic-art-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/islamic-art.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-islamic-art-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-lost-world-tolkien-art-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/lost-world-tolkien-art.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-lost-world-tolkien-art-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-painting-technique-are-artists-acrylics-permanent-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/painting-technique/are-artists-acrylics-permanent.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-painting-technique-are-artists-acrylics-permanent-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-painting-technique-golden-open-acrylics-first-impressions-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/painting-technique/golden-open-acrylics-first-impressions.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-painting-technique-golden-open-acrylics-first-impressions-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-painting-technique-how-oil-painting-made-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/painting-technique/how-oil-painting-made.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-painting-technique-how-oil-painting-made-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-painting-technique-learning-basic-materials-for-oil-painting-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/painting-technique/learning/basic-materials-for-oil-painting.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-painting-technique-learning-basic-materials-for-oil-painting-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-painting-technique-learning-paint-tree-of-life-water-based-oils-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/painting-technique/learning/paint-tree-of-life-water-based-oils.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-painting-technique-learning-paint-tree-of-life-water-based-oils-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-painting-technique-learning-what-type-of-painting-do-you-want-to-create-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/painting-technique/learning/what-type-of-painting-do-you-want-to-create.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-painting-technique-learning-what-type-of-painting-do-you-want-to-create-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-painting-technique-water-mixable-oil-paints-first-impressions-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/painting-technique/water-mixable-oil-paints-first-impressions.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-painting-technique-water-mixable-oil-paints-first-impressions-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-paleo-art-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/paleo-art/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-paleo-art-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-prince-charles-first-official-portrait-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/prince-charles-first-official-portrait.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-prince-charles-first-official-portrait-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-reflections-on-greenbelt-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/reflections-on-greenbelt.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-reflections-on-greenbelt-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-viking-art-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/viking-art.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-viking-art-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-art-whatever-happened-to-nfts-crypto-art-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/art/whatever-happened-to-nfts-crypto-art.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-art-whatever-happened-to-nfts-crypto-art-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-artist-macular-disease-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/artist-macular-disease.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-artist-macular-disease-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-artistic-statement-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/artistic-statement.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-artistic-statement-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-merry-xmas-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/merry-xmas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-merry-xmas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-china-modern-art-painting-in-china-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/china/modern-art-painting-in-china.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-china-modern-art-painting-in-china-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-france-paris-leonardo-da-vinci-exhibition-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/france/paris/leonardo-da-vinci-exhibition.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-france-paris-leonardo-da-vinci-exhibition-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-france-paris-musee-d-orsay-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/france/paris/musee-d-orsay.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-france-paris-musee-d-orsay-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-france-paris-people-watching-in-paris-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/france/paris/people-watching-in-paris.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-france-paris-people-watching-in-paris-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-germany-medieval-germany-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/Europe/germany/medieval-germany.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-germany-medieval-germany-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-portugal-faro-portugals-hidden-gem-of-a-city-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/portugal/faro-portugals-hidden-gem-of-a-city.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-portugal-faro-portugals-hidden-gem-of-a-city-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-portugal-why-i-like-portugal-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/portugal/why-i-like-portugal.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-portugal-why-i-like-portugal-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-spain-exploring-art-and-culture-of-alicante-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/spain/exploring-art-and-culture-of-alicante.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-spain-exploring-art-and-culture-of-alicante-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-spain-girona-arts-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/spain/girona-arts.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-spain-girona-arts-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-derbyshire-buxton-art-gallery-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/Europe/uk/derbyshire/buxton-art-gallery.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-derbyshire-buxton-art-gallery-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-beverley-beverley-beautiful-guildhall-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/beverley/beverley-beautiful-guildhall.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-beverley-beverley-beautiful-guildhall-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-beverley-beverley-open-studios-2024-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/beverley/beverley-open-studios-2024.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-beverley-beverley-open-studios-2024-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-beverley-fred-elwell-painting-grimsby-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/beverley/fred-elwell-painting-grimsby.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-beverley-fred-elwell-painting-grimsby-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-beverley-fred-elwells-craftsman-father-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/beverley/fred-elwells-craftsman-father.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-beverley-fred-elwells-craftsman-father-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-beverley-luxmuralis-beverley-minster-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/beverley/luxmuralis-beverley-minster.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-beverley-luxmuralis-beverley-minster-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-beverley-murals-under-bridge-beverley-beck-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/beverley/murals-under-bridge-beverley-beck.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-beverley-murals-under-bridge-beverley-beck-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-beverley-sand-art-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/beverley/sand-art.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-beverley-sand-art-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-bridlington-bridlington-old-town-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/Europe/uk/east-yorkshire/bridlington/bridlington-old-town.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-bridlington-bridlington-old-town-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-burton-constable-hall-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/Europe/uk/east-yorkshire/burton-constable-hall.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-burton-constable-hall-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-era-annual-exhibition-market-weighton-2024-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/Europe/uk/east-yorkshire/era-annual-exhibition-market-weighton-2024.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-era-annual-exhibition-market-weighton-2024-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-art-exhibition-about-violence-against-women-at-hulls-brynmor-jones-gallery-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/hull/art-exhibition-about-violence-against-women-at-hulls-brynmor-jones-gallery.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-art-exhibition-about-violence-against-women-at-hulls-brynmor-jones-gallery-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-banksy-art-in-hull-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/hull/banksy-art-in-hull.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-banksy-art-in-hull-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-canaletto-in-hull-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/hull/canaletto-in-hull.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-canaletto-in-hull-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-hull-cultural-railway-station-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/hull/hull-cultural-railway-station.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-hull-cultural-railway-station-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-hull-fascinating-guildhall-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/hull/hull-fascinating-guildhall.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-hull-fascinating-guildhall-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-hull-fruit-market-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/hull/hull-fruit-market.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-hull-fruit-market-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-knife-angel-sculpture-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/hull/knife-angel-sculpture.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-knife-angel-sculpture-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-people-watching-hull-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/hull/people-watching-hull.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-people-watching-hull-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-print-collective-burton-constable-2025-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/Europe/uk/east-yorkshire/hull-print-collective-burton-constable-2025.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-print-collective-burton-constable-2025-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-solar-gate-sculpture-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/east-yorkshire/hull/solar-gate-sculpture.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-hull-solar-gate-sculpture-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-john-sprakes-gallery-hornsea-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/Europe/uk/east-yorkshire/john-sprakes-gallery-hornsea.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-john-sprakes-gallery-hornsea-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-sand-art-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/Europe/uk/east-yorkshire/sand-art.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-east-yorkshire-sand-art-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-herefordshire-art-hereford-cathedral-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/europe/uk/herefordshire/art-hereford-cathedral.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-europe-uk-herefordshire-art-hereford-cathedral-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-russia-russian-art-twenty-first-century-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/russia/russian-art-twenty-first-century.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-russia-russian-art-twenty-first-century-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-places-russia-thoughts-about-russian-doll-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/places/russia/thoughts-about-russian-doll.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-places-russia-thoughts-about-russian-doll-mdx" */),
  "component---src-templates-tag-group-js": () => import("./../../../src/templates/tag-group.js" /* webpackChunkName: "component---src-templates-tag-group-js" */)
}

